import type { StoreActionApi } from 'react-sweet-state';
import { createStore, createHook } from 'react-sweet-state';

import { addUFOCustomData } from '@atlaskit/react-ufo/custom-data';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next/types';

import { getApolloClient, markErrorAsHandled } from '@confluence/graphql';
import { getMonitoringClient } from '@confluence/monitoring';
import { Attribution } from '@confluence/error-boundary';

import { postJson, fetchJSON } from './utils/fetch';
import { LicensedProductsQuery } from './graphql/LicensedProductsQuery.graphql';
import {
	LicenseStatus,
	type LicensedProductsQuery as LicensedProductsQueryType,
} from './graphql/__types__/LicensedProductsQuery';
import { waitUntil } from './utils/wait';
import { fetchLicenseInformation } from './services/xflow';
import type { EntryPointId } from './LoomCrossSellPopupCard/BaseLoomPopupCard';

export enum LoomTenantProvisionStatus {
	PROVISIONED = 'provisioned',
	NOT_PROVISIONED = 'not-provisioned',
	ERROR = 'error,',
}

export enum LoomUserPermissionStatus {
	PERMITTED = 'permitted',
	NOT_PERMITTED = 'not-permitted',
	ERROR = 'error,',
}

const getLoomTenantProvisionStatus = async (): Promise<LoomTenantProvisionStatus> => {
	try {
		const licensedProducts = await getApolloClient().query<LicensedProductsQueryType>({
			query: LicensedProductsQuery,
		});

		const isTenantLicensed = licensedProducts.data.confluence_tenantContext?.licensedProducts.some(
			(product) => product.productKey === 'loom' && product.licenseStatus === LicenseStatus.ACTIVE,
		);
		return isTenantLicensed
			? LoomTenantProvisionStatus.PROVISIONED
			: LoomTenantProvisionStatus.NOT_PROVISIONED;
	} catch (e) {
		getMonitoringClient().submitError(e, {
			attribution: Attribution.CONTENT_TYPES,
		});
		markErrorAsHandled(e);
		return LoomTenantProvisionStatus.ERROR;
	}
};

export const getLoomUserPermissionStatus = async (
	cloudId: string,
	userId: string | null,
	environment: string,
): Promise<LoomUserPermissionStatus> => {
	const resourceId = `ari:cloud:loom::site/${cloudId}`;
	try {
		let permitted;
		// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
		if (process.env.REACT_SSR) {
			const identityUrl =
				environment === 'PRODUCTION'
					? 'https://id.prod.atl-paas.net'
					: 'https://id.staging.atl-paas.net';
			const principalId = `ari:cloud:identity::user/${userId}`;

			permitted = await fetchJSON(
				`${identityUrl}/v1/permissions/permitted?permissionId=write&resourceId=${resourceId}&principalId=${principalId}`,
			);
		} else {
			const response = await postJson(`/gateway/api/permissions/permitted`, {
				permissionId: 'write',
				resourceId,
				checkProductStatus: true,
			});
			permitted = response?.permitted;
		}
		return !!permitted
			? LoomUserPermissionStatus.PERMITTED
			: LoomUserPermissionStatus.NOT_PERMITTED;
	} catch (e) {
		getMonitoringClient().submitError(e, {
			attribution: Attribution.CONTENT_TYPES,
		});
		markErrorAsHandled(e);
		return LoomUserPermissionStatus.ERROR;
	}
};

export type LoomEntryPointVariantOption =
	| 'BETA'
	| 'CO_USE'
	| 'CROSS_FLOW'
	| 'CROSS_JOIN'
	| 'DISABLED'
	| 'NONE';

export type LoomEntryPointVariant = {
	entryPointVariant: LoomEntryPointVariantOption;
	error?: boolean;
	loading?: boolean;
};

export type LoomEntryPointVariantStateType = {
	loomEntryPointVariant: LoomEntryPointVariant;
};

interface CrossFlowSuccessCheckProps {
	cloudId: string;
	onCrossflowFailure?: () => void;
	createAnalyticsEvent: CreateUIAnalyticsEvent;
	sourceComponent: EntryPointId;
	setIsPollingStatus: (isPolling: boolean) => void;
}

type LoomEntryPointVariantActionType = {
	initLoomEntryPointVariant: (
		cloudId: string,
		userId: string | null,
		environment: string,
	) => ({ getState, setState }: StoreActionApi<LoomEntryPointVariantStateType>) => void;

	crossFlowSuccessCheck: (
		props: CrossFlowSuccessCheckProps,
	) => ({ setState }: StoreActionApi<LoomEntryPointVariantStateType>) => void;
};

const initialState = {
	loomEntryPointVariant: window.__SSR_LOOM_ENTRY_POINT__
		? window.__SSR_LOOM_ENTRY_POINT__
		: { entryPointVariant: 'NONE' as LoomEntryPointVariantOption },
};

// * NOTE: CHANGES TO THIS LOGIC MUST ALSO BE REPLICATED IN next/packages/loom-utils/src/preloadRecordLoomButton.ts TO ENSURE THE CORRECT EXPERIENCE DURING SSR
export const loomEntryPointVariantActions: LoomEntryPointVariantActionType = {
	initLoomEntryPointVariant:
		(cloudId: string, userId: string | null, environment: string) =>
		async ({ getState, setState }) => {
			const state = getState();

			if (state.loomEntryPointVariant.entryPointVariant !== 'NONE') {
				return;
			}

			setState({
				loomEntryPointVariant: { entryPointVariant: 'CROSS_FLOW', loading: true },
			});

			const loomTenantProvisionStatus = await getLoomTenantProvisionStatus();

			if (loomTenantProvisionStatus === LoomTenantProvisionStatus.NOT_PROVISIONED) {
				setState({
					loomEntryPointVariant: { entryPointVariant: 'CROSS_FLOW' },
				});
				return;
			}

			if (loomTenantProvisionStatus === LoomTenantProvisionStatus.ERROR) {
				setState({
					loomEntryPointVariant: { entryPointVariant: 'CROSS_FLOW', error: true },
				});
				return;
			}

			setState({
				loomEntryPointVariant: { entryPointVariant: 'CROSS_JOIN', loading: true },
			});

			const loomUserPermissionStatus = await getLoomUserPermissionStatus(
				cloudId,
				userId,
				environment,
			);

			if (loomUserPermissionStatus === LoomUserPermissionStatus.NOT_PERMITTED) {
				setState({
					loomEntryPointVariant: { entryPointVariant: 'CROSS_JOIN' },
				});
				return;
			}

			if (loomUserPermissionStatus === LoomUserPermissionStatus.ERROR) {
				setState({
					loomEntryPointVariant: { entryPointVariant: 'CROSS_JOIN', error: true },
				});
				return;
			}
			setState({
				loomEntryPointVariant: { entryPointVariant: 'CO_USE' },
			});
		},
	crossFlowSuccessCheck:
		({
			cloudId,
			onCrossflowFailure,
			createAnalyticsEvent,
			sourceComponent,
			setIsPollingStatus,
		}: CrossFlowSuccessCheckProps) =>
		async ({ setState }) => {
			// refetch license information since TCS doesn't update automatically
			try {
				const { ok: hasLoom, tries } = await waitUntil({
					fn: () => fetchLicenseInformation(cloudId),
					condition: (info) => info.products['loom']?.state === 'ACTIVE',
					maxTries: 2, // arbitrarily deciding on just 1 retry until we have more data to understand the eventual consistency here
					retryWait: 500,
				});
				addUFOCustomData({
					pollingTries: tries,
					hasLoom,
				});

				if (hasLoom) {
					// don't bother getLoomUserPermissionStatus here, we assume if the site has loom, the admin will have been given a license during provisioning
					setState({
						loomEntryPointVariant: { entryPointVariant: 'CO_USE' },
					});
					setIsPollingStatus(false);

					//This is needed for SSR to work correctly, since we skip logic in useLoomEntryPointVariant.ts if the entry point was SSR-ed
					window.__SSR_LOOM_ENTRY_POINT__ = { entryPointVariant: 'CO_USE' };
				} else {
					onCrossflowFailure && onCrossflowFailure();
				}
				createAnalyticsEvent({
					type: 'sendOperationalEvent',
					data: {
						actionSubject: 'confluenceLoomUtils.fetchLicenseInformation',
						action: 'success',
						source: 'confluenceLoomUtils',
						attributes: {
							hasLoom,
							tries,
							sourceComponent,
						},
					},
				}).fire();
			} catch (error) {
				onCrossflowFailure && onCrossflowFailure();
				createAnalyticsEvent({
					type: 'sendOperationalEvent',
					data: {
						actionSubject: 'confluenceLoomUtils.fetchLicenseInformation',
						action: 'fail',
						source: 'confluenceLoomUtils',
						attributes: {
							error: error.message,
							sourceComponent,
						},
					},
				}).fire();
			}
		},
};

const loomEntryPointVariantStore = createStore<
	LoomEntryPointVariantStateType,
	LoomEntryPointVariantActionType
>({
	initialState,
	actions: loomEntryPointVariantActions,
	name: 'loomEntryPointVariantState',
});

export const useLoomEntryPointVariantState = createHook(loomEntryPointVariantStore);
